import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "modals-communicate-information-via-a-secondary-window-and-allow-the-user-to-maintain-the-context-of-a-particular-task"
    }}>{`Modals communicate information via a secondary window and allow the user to maintain the context of a particular task.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Format</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Modals interrupt user workflow by design. They are most effective when a task must be completed before a user can continue. While effective when used correctly, modals should be used sparingly to limit disruption to a user experience.`}</p>
    <h4 {...{
      "id": "dismissal"
    }}>{`Dismissal`}</h4>
    <p>{`Modals may be dismissed in 3 ways:`}</p>
    <ul>
      <li parentName="ul">{`Using the “✕” in the upper right-hand corner of the modal`}</li>
      <li parentName="ul">{`Pressing the `}<inlineCode parentName="li">{`ESC`}</inlineCode>{` key`}</li>
      <li parentName="ul">{`Clicking or touching outside of the modal`}</li>
    </ul>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <p>{`The modal is composed of three distinct zones: A header, the body, and a footer. Components (eg. data table, form, progress indicator) can occupy the full width of the modal.`}</p>
    <h4 {...{
      "id": "header"
    }}>{`Header`}</h4>
    <p>{`The header of your modal should mirror the action that launched the modal. Headers must include a close button “✕” in the upper right-hand corner of the modal. You can also include an optional label above your header text. This is an opportunity to offer additional context.`}</p>
    <h4 {...{
      "id": "body"
    }}>{`Body`}</h4>
    <p>{`A modal should have minimal body content. Components that may be used in modals include: form fields, text area, select, and radio buttons. Text, including the paragraph component, should only be 75% of the modal’s width.`}</p>
    <h4 {...{
      "id": "footer"
    }}>{`Footer`}</h4>
    <p>{`The footer area of a modal typically contains either one or two `}<a parentName="p" {...{
        "href": "/components/button"
      }}>{`buttons`}</a>{`. Do not include three buttons in the footer of your modal. If you need to include a “help” or other non-primary action, include it as a link in the modal’s body.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "52.44565217391305%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of modal with one button.",
        "title": "Example of modal with one button.",
        "src": "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/fb070/modal-usage-4a.png",
        "srcSet": ["/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/d6747/modal-usage-4a.png 288w", "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/09548/modal-usage-4a.png 576w", "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/fb070/modal-usage-4a.png 1152w", "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/c3e47/modal-usage-4a.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "51.902173913043484%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of modal with two buttons.",
        "title": "Example of modal with two buttons.",
        "src": "/static/6c65ccb91af6123ab6461fd299b121a0/fb070/modal-usage-4b.png",
        "srcSet": ["/static/6c65ccb91af6123ab6461fd299b121a0/d6747/modal-usage-4b.png 288w", "/static/6c65ccb91af6123ab6461fd299b121a0/09548/modal-usage-4b.png 576w", "/static/6c65ccb91af6123ab6461fd299b121a0/fb070/modal-usage-4b.png 1152w", "/static/6c65ccb91af6123ab6461fd299b121a0/c3e47/modal-usage-4b.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "transactional-modal"
    }}>{`Transactional modal`}</h3>
    <p>{`Transactional modals are used to validate user decisions or to gain secondary confirmation from the user. Typically, the modal requests either a ‘yes’ or ‘no’ response.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "51.902173913043484%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "transactional modal",
        "title": "transactional modal",
        "src": "/static/214ec26dfb9018366c29fa5accdbc21a/fb070/modal-usage-1.png",
        "srcSet": ["/static/214ec26dfb9018366c29fa5accdbc21a/d6747/modal-usage-1.png 288w", "/static/214ec26dfb9018366c29fa5accdbc21a/09548/modal-usage-1.png 576w", "/static/214ec26dfb9018366c29fa5accdbc21a/fb070/modal-usage-1.png 1152w", "/static/214ec26dfb9018366c29fa5accdbc21a/c3e47/modal-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "input-modal"
    }}>{`Input modal`}</h3>
    <p>{`Modals used in this case include input areas that the user may interact with. These may include but are not limited to forms, dropdowns, selectors, and links.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.1141304347826%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "input modal",
        "title": "input modal",
        "src": "/static/d74a33c81f5eb02c9d7e611f9d363bf3/fb070/modal-usage-2.png",
        "srcSet": ["/static/d74a33c81f5eb02c9d7e611f9d363bf3/d6747/modal-usage-2.png 288w", "/static/d74a33c81f5eb02c9d7e611f9d363bf3/09548/modal-usage-2.png 576w", "/static/d74a33c81f5eb02c9d7e611f9d363bf3/fb070/modal-usage-2.png 1152w", "/static/d74a33c81f5eb02c9d7e611f9d363bf3/c3e47/modal-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "passive-modal"
    }}>{`Passive modal`}</h3>
    <p>{`The passive modal is a style of notification. Passive modals are highly disruptive to a user experience, and should only be used if a user must address something immediately.`}</p>
    <p>{`Passive modal notifications are persistent on-screen. Users must either engage with or dismiss the notification.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.92391304347826%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Passive modal",
        "title": "Passive modal",
        "src": "/static/6a4abc3c2ad68b9562cb5dd64119c155/fb070/modal-usage-3.png",
        "srcSet": ["/static/6a4abc3c2ad68b9562cb5dd64119c155/d6747/modal-usage-3.png 288w", "/static/6a4abc3c2ad68b9562cb5dd64119c155/09548/modal-usage-3.png 576w", "/static/6a4abc3c2ad68b9562cb5dd64119c155/fb070/modal-usage-3.png 1152w", "/static/6a4abc3c2ad68b9562cb5dd64119c155/c3e47/modal-usage-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      